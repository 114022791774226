import delve from 'dlv'
import moment from 'moment'
import {
  getLocaleCurr,
  isUndefined,
  getPluralizedKey,
  getNormalizedAccountDomain,
  CONST
} from '../../utils'
import { useSelector } from 'react-redux'
import { md5 } from '../../utils/crypto'
import { BUILD, RELEASE_SCOPE } from '../../config'
import colorPalette from '../../styles/themes/freshworks/palette'
import getSubdomain from '../../utils/getSubdomainFromUrl'
import defaultTranslation from '../../default_translations/en-us.json'
import { PHONE_CREDIT_SPLIT } from './phonecredits/utils'
import { Anchor } from '../../components/Typography'

//3 HOURS
const REACTIVATION_TRIAL_PERIOD = 10800000
const PLAN_QUANTITY = 'plan_quantity'

const BILLING_CYCLE = 'billing_cycle'
const DISCOUNT_ENTITY_TYPE = {
  PROMOTIONAL_CREDITS: 'promotional_credits',
  DOCUMENT_LEVEL_COUPON: 'document_level_coupon',
  ITEM_LEVEL_COUPON: 'item_level_coupon'
}

const ADDON_BOX_SIZES = {
  FULL_CARD: 'FULL_CARD',
  DEPENDENT_CARD: 'DEPENDENT_CARD',
  HALF_CARD: 'HALF_CARD'
}

const PLAN_BILLING_PERIOD = {
  ANNUAL: 12,
  HALF_YEAR: 6,
  QUARTER: 3,
  MONTH: 1
}
const INVOICE_STATUS = {
  POSTED: 'POSTED',
  PAID: 'PAID',
  PAYMENT_DUE: 'PAYMENT_DUE',
  NOT_PAID: 'NOT_PAID',
  VOIDED: 'VOIDED',
  PENDING: 'PENDING'
}
const INVOICES_I18N_CTX = 'bills.invoices_status'

const invoicesStatusAndPriority = {
  [INVOICE_STATUS.NOT_PAID]: {
    text: `${INVOICES_I18N_CTX}.not_paid`,
    priority: 3
  },
  [INVOICE_STATUS.PAID]: {
    text: `${INVOICES_I18N_CTX}.paid`,
    priority: 2
  },
  [INVOICE_STATUS.PAYMENT_DUE]: {
    text: `${INVOICES_I18N_CTX}.payment_due`,
    priority: 0
  },
  [INVOICE_STATUS.PENDING]: {
    text: `${INVOICES_I18N_CTX}.pending`,
    priority: 5
  },
  [INVOICE_STATUS.POSTED]: {
    text: `${INVOICES_I18N_CTX}.posted`,
    priority: 1
  },
  [INVOICE_STATUS.VOIDED]: {
    text: `${INVOICES_I18N_CTX}.voided`,
    priority: 4
  }
}
const BILLING_CYCLE_DESCRIPTION_FOR_CONTRACTS_INTL_KEY = {
  [PLAN_BILLING_PERIOD.ANNUAL]: 'bills.billing_frequency_contracts.annual',
  [PLAN_BILLING_PERIOD.HALF_YEAR]: 'bills.billing_frequency_contracts.half_year',
  [PLAN_BILLING_PERIOD.QUARTER]: 'bills.billing_frequency_contracts.quarter',
  [PLAN_BILLING_PERIOD.MONTH]: 'bills.billing_frequency_contracts.month'
}

const SELF_SERVE_OFFLINE = {
  ON: 'ON',
  OFF: 'OFF'
}

const SUBSCRIPTION_ROUTES = {
  SUBSCRIPTION_LISTING: '/subscriptions',
  INVOICES: '/subscriptions/invoices',
  SHIPPING_ADDRESS: '/subscriptions/shipping-addresses',
  BILLING_ADDRESS: '/subscriptions/billing-addresses'
}

const PAGE_DISPLAY_KEY_MAPPING = {
  SUBSCRIPTION_LISTING: 'bills.all_subscriptions',
  INVOICES: 'bills.invoices',
  BILLING_ADDRESS: 'bills.bill_addresses',
  SHIPPING_ADDRESS: 'bills.shipping_addresses',
  CHECKOUT: 'bills.manage_plan',
  SUBSCRIPTION_DETAILS: 'details'
}

const { SUBSCRIPTION_LISTING, INVOICES, SHIPPING_ADDRESS, BILLING_ADDRESS } = SUBSCRIPTION_ROUTES

const AUTO_ACTIVATE_ACTION = {
  CANCEL: 'cancel',
  ACTIVATE: 'activate'
}

const getListOfBreadcrumbs = (
  displayKeyList,
  categoryDisplayName = null,
  subscriptionId = null
) => {
  return displayKeyList.map((displayKey, index) => {
    const isDetailsPage = displayKey === PAGE_DISPLAY_KEY_MAPPING.SUBSCRIPTION_DETAILS
    if (index == displayKeyList.length - 1) {
      return {
        displayKey: isDetailsPage ? categoryDisplayName : displayKey
      }
    } else {
      const routeKey = Object.keys(PAGE_DISPLAY_KEY_MAPPING).find(
        (pageKey) => displayKey === PAGE_DISPLAY_KEY_MAPPING[pageKey]
      )
      return {
        displayKey: isDetailsPage ? categoryDisplayName : displayKey,
        route: isDetailsPage ? getDetailsPageURL(subscriptionId) : SUBSCRIPTION_ROUTES[routeKey]
      }
    }
  })
}

const subscriptionNavList = [
  {
    iconName: 'all_subscriptions',
    displayNameTranslationKey: PAGE_DISPLAY_KEY_MAPPING.SUBSCRIPTION_LISTING,
    to: SUBSCRIPTION_LISTING
  },
  {
    iconName: 'invoices',
    displayNameTranslationKey: PAGE_DISPLAY_KEY_MAPPING.INVOICES,
    to: INVOICES
  },
  {
    iconName: 'addresses',
    displayNameTranslationKey: 'bills.addresses',
    hasSubLinks: true,
    subRoutes: [
      {
        displayNameTranslationKey: PAGE_DISPLAY_KEY_MAPPING.SHIPPING_ADDRESS,
        to: SHIPPING_ADDRESS
      },
      {
        displayNameTranslationKey: PAGE_DISPLAY_KEY_MAPPING.BILLING_ADDRESS,
        to: BILLING_ADDRESS
      }
    ]
  }
]

const PLAN_AND_ADDON_PRICING_MODEL_TYPES = {
  PER_UNIT: 'per_unit',
  FLAT_FEE: 'flat_fee',
  TIERED: 'tiered',
  VOLUME: 'volume',
  STAIRSTEP: 'stairstep'
}
const ADDON_PER_PRICING_UNIT = {
  USER: 'user',
  ACCOUNT: 'account',
  PACK: 'pack',
  AGENT: 'agent'
}
const ADDON_VOLUME_UNITS = {
  CONTACTS: 'contacts',
  MUV: 'muv'
}
const GROUPED_ADDON = {
  ASSERT_PACK: 'asset_pack'
}
const CREDIT_TYPES = {
  REFUNDABLE: 'refundable'
}
const MONTH_TO_DISPLAY_NAME = {
  0: 'bills.jan',
  1: 'bills.feb',
  2: 'bills.mar',
  3: 'bills.apr',
  4: 'bills.may',
  5: 'bills.jun',
  6: 'bills.jul',
  7: 'bills.aug',
  8: 'bills.sep',
  9: 'bills.oct',
  10: 'bills.nov',
  11: 'bills.dec'
}

const CONTRACT_STATUS = {
  ACTIVE: 'active'
}
const SUBSCRIPTION_STATUS = {
  TRIAL: 'in_trial',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  PENDING_UPDATE: 'pending_update',
  FUTURE: 'future',
  NON_RENEWAL: 'non_renewing', // when subscription will be cancelled in future
  PAUSED: 'paused'
}
const DEFAULT_CATEGORY_TYPE = 'system_default'
const CARD_EXPIRED = 'expired'
const CARD_ACTIVE = 'valid'
const CARD_EXPIRING = 'expiring'
const SALES360_BUNDLE_NAME = 'sales360'
const BILLING_BASE_PATH = '/subscriptions'
const ADDON_NON_RECURRING = 'non_recurring'
const CUSTOMER_AUTO_COLLECTION_OFF = 'off'
const CUSTOMER_AUTO_COLLECTION_ON = 'on'
const ANNUAL_SUB = '12_month'
const SUB_ACTIVATED_SUCCESSFULLY = 'subActivatedSuccess'
const INTENT_CAPTURED_SUCCESSFULLY = 'intentCaptureSuccess'
const TRIAL_CHANGE_SUCCESSFULLY = 'trialChangedSuccess'
const PAYMENT_METHOD_UPDATE_SUCCESS = 'paymentUpdateSuccess'
const PAYMENT_PAGE_URL_FETCH_FAILED = 'paymentPageFailed'
const SUBSCRIPTION_UPDATED_SUCCESSFULLY = 'subUpdatesSuccess'
const SUCCESS = 'success'
const FAILURE = 'failure'
const SUBSCRIPTION_STATUS_LIST = [
  TRIAL_CHANGE_SUCCESSFULLY,
  SUBSCRIPTION_UPDATED_SUCCESSFULLY,
  PAYMENT_METHOD_UPDATE_SUCCESS,
  PAYMENT_PAGE_URL_FETCH_FAILED,
  INTENT_CAPTURED_SUCCESSFULLY
]
const SUBSCRIPTION_POLLING_DURATION = 30000
const SUBSCRIPTION_POLLING_INTERVAL = 3000
const PHONE_CREDITS_DELAY_DURATION = 2000
const BOT_SESSIONS_DELAY_DURATION = 3000
const DAY_PASS_DELAY_DURATION = 3000
const ORCH_PACK_DELAY_DURATION = 5000
const ORCH_PACK_POLL_DURATION_IN_MS = 20000
const UI_COMP = {
  NUMBER_INPUT_COMP: 'number_input',
  SLIDER_COMP: 'slider',
  NONE: 'none',
  DROPDOWN: 'dropdown'
}
const DEFAULT = 'default'
const ADDON_UNIT_TO_UI_COMPONENT_MAPPING = {
  [PLAN_AND_ADDON_PRICING_MODEL_TYPES.FLAT_FEE]: {
    [DEFAULT]: UI_COMP.NONE
  },
  [PLAN_AND_ADDON_PRICING_MODEL_TYPES.PER_UNIT]: {
    [DEFAULT]: UI_COMP.NUMBER_INPUT_COMP,
    [ADDON_PER_PRICING_UNIT.USER]: UI_COMP.NONE,
    [ADDON_PER_PRICING_UNIT.ACCOUNT]: UI_COMP.NUMBER_INPUT_COMP,
    [ADDON_PER_PRICING_UNIT.PACK]: UI_COMP.NUMBER_INPUT_COMP
  },
  [PLAN_AND_ADDON_PRICING_MODEL_TYPES.VOLUME]: {
    [DEFAULT]: UI_COMP.SLIDER_COMP,
    [ADDON_VOLUME_UNITS.CONTACTS]: UI_COMP.SLIDER_COMP,
    [ADDON_VOLUME_UNITS.MUV]: UI_COMP.SLIDER_COMP
  },
  [PLAN_AND_ADDON_PRICING_MODEL_TYPES.TIERED]: {
    [DEFAULT]: UI_COMP.SLIDER_COMP
  },
  [PLAN_AND_ADDON_PRICING_MODEL_TYPES.STAIRSTEP]: {
    [DEFAULT]: UI_COMP.DROPDOWN
  }
}

const MANAGED_ASSETS_GROUPED_ADDONS_ID = {
  NUMBER_PACKETS: '500_packet_v3',
  UNLIMITED_PACKETS: 'unlimited_packet_v3'
}

const DEFAULT_TIER_STARTING_UNIT = 501

const isSubscriptionPlanPriceLessThanCatalog = (subscription, curSubscription, planObj) => {
  const { planUnitPrice, billingPeriod, planId, currencyCode: unchangedCurrencyCode } = subscription
  const { currencyCode: changedCurrencyCode, planId: changedPlanId } = curSubscription
  const planValue = getPerPeriodUnitPlanValue(planObj)
  if (changedPlanId === planId && unchangedCurrencyCode === changedCurrencyCode) {
    return planUnitPrice / billingPeriod < planValue
  }
  return false
}

const getConvertedPriceValue = (value, currency, needLocaleCurr = true) => {
  //Currently not support any non decimal currencies. Hence removing the currency type check
  value = value / 100
  return needLocaleCurr ? getLocaleCurr(value, currency.toUpperCase()) : value
}
const getLowestCurrencyValue = (upperCurrencyValue) => {
  //Currently not support any non decimal currencies. Hence no currency check
  return Number((upperCurrencyValue * 100).toFixed(2))
}
const isPhoneCreditAddon = (addon = {}) => {
  const { id } = addon
  return id.includes('phonecredits')
}
const isBotSessionAddon = (addon = {}) => {
  const { id } = addon
  return id.includes('bot_session_packs_')
}
const isDayPassAddon = ({ id = '' }) => {
  return id.includes('_daypass_')
}
const isConnectorAppsAddon = ({ id = '' }) => {
  return id.includes('connector_app_tasks')
}
const isConnectorAppsAutoRechargeAddon = ({ id = '' }) => {
  return id.includes('connector_app_tasks_auto_recharge')
}

const isBotAutoRechargeAddon = (addon) => {
  const { id } = addon
  return id.includes('bot_auto_recharge_')
}
const isOrchestrationAddon = (addon) => {
  const { id } = addon
  return id.includes('fs_orch_pack')
}
const gotoPage = (history, page, queryString = '') => {
  history.push({
    pathname: page,
    search: queryString
  })
}

const getCurSubDomainInfo = (id, accounts, isBundle) => {
  if (accounts.length > 0) {
    for (let account of accounts) {
      //for bundle check the bundle sub identifier else check the account id
      if (
        (isBundle && account.bundleIdentifier === id && account.anchor) ||
        (!isBundle && account.id === id)
      ) {
        return {
          url: account.urls[0],
          domain: getNormalizedAccountDomain(account)
        }
      }
    }
  }
  return {}
}
const isPlanUpgradeRequest = (
  newSubscription = {},
  curSubscription = {},
  planObjects = {},
  featureMatrix
) => {
  const { currencyCode: curCurrencyCode, planId: curPlanId } = curSubscription
  const { currencyCode: newCurrencyCode, planId: newPlanId } = newSubscription
  const curPlanMetaObj = getPlanMetaObj(curSubscription, planObjects)
  const newPlanMetaObj = getPlanMetaObj(newSubscription, planObjects)
  const curPlanObjPerUnitPrice = getPerPeriodUnitPlanValue(curPlanMetaObj)
  const newPlanObjPerUnitPrice = getPerPeriodUnitPlanValue(newPlanMetaObj)
  const curPlanCategory = getCategoryInfoForPlan(curPlanId, featureMatrix)
  const newPlanCategory = getCategoryInfoForPlan(newPlanId, featureMatrix)
  const doCategoryCheck = !isUndefined(curPlanCategory) && !isUndefined(newPlanCategory)
  let { index: curPlanPriority, hasFreePlan: curPlanCategoryHasFreePlan } = getPlanPriorityIndex(
    curSubscription,
    planObjects,
    featureMatrix,
    doCategoryCheck
  )
  let { index: newPlanPriority, hasFreePlan: newPlanCategoryHasFreePlan } = getPlanPriorityIndex(
    newSubscription,
    planObjects,
    featureMatrix,
    doCategoryCheck
  )
  if (curPlanCategoryHasFreePlan && !newPlanCategoryHasFreePlan) {
    newPlanPriority += 1
  } else if (!curPlanCategoryHasFreePlan && newPlanCategoryHasFreePlan) {
    curPlanPriority += 1
  }
  const isSameTypeOfPlan =
    delve(curPlanMetaObj, 'metaData.internalPlanIdentifier', '') ===
    delve(newPlanMetaObj, 'metaData.internalPlanIdentifier', '1')
  let isUpgrade = true
  if (newPlanMetaObj && curPlanMetaObj) {
    if (
      curCurrencyCode === newCurrencyCode &&
      (!doCategoryCheck ||
        curPlanCategory === newPlanCategory || // which means doCategoryCheck
        curPlanPriority === newPlanPriority) // which means doCategoryCheck && curPlanCategory !== newPlanCategory
    ) {
      isUpgrade = newPlanObjPerUnitPrice >= curPlanObjPerUnitPrice
    } else {
      isUpgrade = newPlanPriority >= curPlanPriority
    }
  }
  //if same type of plan across billing frequency or currency or category then don't treat it as downgrade
  return isUpgrade || isSameTypeOfPlan
}
const getPlanPriorityIndex = (
  subscription = {},
  planObjects = {},
  featureMatrix,
  checkWithinCategory
) => {
  const { planId, billingPeriod, billingPeriodUnit, currencyCode } = subscription
  let plans = delve(
    planObjects,
    `${currencyCode.toLowerCase()}.${billingPeriodUnit}.${billingPeriod}`
  )
  if (checkWithinCategory) {
    const newPlans = {}
    for (let plan in plans) {
      if (
        getCategoryInfoForPlan(plan, featureMatrix) ===
        getCategoryInfoForPlan(planId, featureMatrix)
      ) {
        newPlans[plan] = plans[plan]
      }
    }
    plans = newPlans
  }
  const sortedPlans = getSortedPlans(plans)
  const firstPlan = plans[sortedPlans[0]]
  const hasFreePlan = firstPlan.price === 0
  return { index: sortedPlans.indexOf(planId), hasFreePlan }
}
const getSortedPlans = (plans = {}) => {
  return Object.keys(plans).sort((a, b) => {
    a = plans[a]
    b = plans[b]
    return a.price - b.price
  })
}
const getPlanMetaObj = (subscriptionObj = {}, planObjects = {}) => {
  const { planId, billingPeriod, billingPeriodUnit, currencyCode } = subscriptionObj
  return (
    delve(
      planObjects,
      `${currencyCode.toLowerCase()}.${billingPeriodUnit}.${billingPeriod}.${planId}`
    ) || {}
  )
}
const parseNGetSelectedAddons = (addons = []) => {
  const selectedAddons = {}
  addons.forEach((addon) => {
    selectedAddons[addon.id] = { ...addon }
  })
  return selectedAddons
}
const getPerPeriodUnitPlanValue = (planObj = {}) => {
  const { period = 1, price = 0 } = planObj
  return price / period
}
const getAddonsBusinessUnit = (addonObj = {}, selectedAddons = {}) => {
  let {
    id,
    pricingModel,
    metaData: { unitQuantity },
    unit
  } = addonObj
  let isPhoneCredit = isPhoneCreditAddon(addonObj)
  const isAddonSelected = selectedAddons[id] !== undefined
  if (isAddonSelected) {
    const curSelAddon = selectedAddons[id]
    let { quantity } = curSelAddon
    switch (pricingModel) {
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.PER_UNIT:
        if (isPhoneCredit) {
          return quantity * unitQuantity
        }
        switch (unit) {
          case ADDON_PER_PRICING_UNIT.ACCOUNT:
          default:
            return quantity * unitQuantity
          case ADDON_PER_PRICING_UNIT.USER:
            return quantity
          case ADDON_PER_PRICING_UNIT.AGENT:
            return quantity
        }
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.STAIRSTEP:
        return quantity
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.VOLUME:
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.TIERED:
        return quantity * unitQuantity
      default:
        break
    }
  }
  return 0
}
const getRemainingTrialDays = (trialEndDate = 0) => {
  if (trialEndDate) {
    return -moment({ hours: 0 }).diff(trialEndDate, 'days')
  }
  return 0
}

const doesQuantityFallUnderTier = (quantity, tier) => {
  const { startingUnit, endingUnit } = tier
  return quantity >= startingUnit && (!endingUnit || quantity <= endingUnit)
}

const getPerPeriodUnitAddonAmountForVolumeBasedAddon = (
  price,
  tiers,
  minChargebeeUnitToStartWith,
  quantity
) => {
  let lowestStartingUnit = Number.POSITIVE_INFINITY,
    lowestTierPrice = Number.POSITIVE_INFINITY
  for (let i = 0, len = tiers.length; i < len; i++) {
    const { startingUnit, endingUnit, price: tierPrice } = tiers[i]
    if (
      tierPrice &&
      //following check will pass for tiers whose starting unit is greater than minChargebee unit to start with
      (startingUnit >= minChargebeeUnitToStartWith ||
        //following check will pass for tiers within which the minChargebee unit falls under
        (startingUnit <= minChargebeeUnitToStartWith &&
          (!endingUnit || minChargebeeUnitToStartWith <= endingUnit)))
    ) {
      lowestStartingUnit = startingUnit < lowestStartingUnit ? startingUnit : lowestStartingUnit
      lowestTierPrice = startingUnit === lowestStartingUnit ? tierPrice : lowestTierPrice
      if (doesQuantityFallUnderTier(quantity, tiers[i])) {
        price = tierPrice
        break
      }
    }
  }
  return price ? price : lowestTierPrice
}
const getPerPeriodUnitAddonAmountForStairstepAddon = (tiers, quantity) => {
  //The min value quantity will be already set through the selectAddon call from Addons.js
  for (let i = 0, len = tiers.length; i < len; i++) {
    const curTier = tiers[i]
    if (doesQuantityFallUnderTier(quantity, curTier)) {
      return curTier.price
    }
  }
  return 0
}
const getPerPeriodUnitAddonAmount = (
  subscription,
  addonObj,
  selectedAddons,
  curSelPlanId,
  isBillingCycleBasedAddon = false
) => {
  //setting period to 1 to support non recurring addons
  let { id, price, tiers, pricingModel, period = 1, currencyCode: newCurrency } = addonObj
  let { currencyCode: oldCurrency, planId: oldPlanId } = subscription
  const isPlanChanged = oldPlanId !== curSelPlanId || oldCurrency !== newCurrency
  const alreadyPurchasedAddon = delve(subscription, 'addons', []).filter((addon) => addon.id === id)
  if (
    !isPlanChanged &&
    alreadyPurchasedAddon.length &&
    pricingModel !== PLAN_AND_ADDON_PRICING_MODEL_TYPES.VOLUME &&
    pricingModel !== PLAN_AND_ADDON_PRICING_MODEL_TYPES.STAIRSTEP &&
    pricingModel !== PLAN_AND_ADDON_PRICING_MODEL_TYPES.TIERED
  ) {
    price = alreadyPurchasedAddon[0].unitPrice
  }
  const { quantity = 0 } = selectedAddons[id] || {}
  const minChargebeeUnitToStartWith = getLowestApplicableChargebeeQuantity(addonObj)
  const isPhoneCredit = isPhoneCreditAddon(addonObj)
  const isBotSession = isBotSessionAddon(addonObj)
  // eslint-disable-next-line default-case
  switch (pricingModel) {
    case PLAN_AND_ADDON_PRICING_MODEL_TYPES.VOLUME:
      price = getPerPeriodUnitAddonAmountForVolumeBasedAddon(
        price,
        tiers,
        minChargebeeUnitToStartWith,
        quantity
      )
      break
    case PLAN_AND_ADDON_PRICING_MODEL_TYPES.STAIRSTEP:
      price = getPerPeriodUnitAddonAmountForStairstepAddon(tiers, quantity) / period
      break
  }
  return isPhoneCredit || isBotSession || isBillingCycleBasedAddon ? price : price / period
}
const getBillingAmountOfTieredPricingAddon = (
  addonObj = {},
  selectedAddons = {},
  subscription,
  getPerPeriodUnit = false
) => {
  const { billingPeriod } = subscription
  const { id, tiers = [] } = addonObj
  const { quantity = 0 } = delve(selectedAddons, `${id}`, {})
  let totalAmount = 0
  tiers.forEach((tier) => {
    const { startingUnit, endingUnit, price: tierPrice } = tier
    if (endingUnit && quantity > endingUnit) {
      //+1 since we don't want the difference we want the count
      totalAmount += (endingUnit - startingUnit + 1) * tierPrice
    } else if (doesQuantityFallUnderTier(quantity, tier)) {
      totalAmount += (quantity - startingUnit + 1) * tierPrice
    }
  })
  //for non recurring addon we should not take billing period into account.
  return getPerPeriodUnit || isNonRecurringAddon(addonObj)
    ? totalAmount
    : billingPeriod * totalAmount
}
const getLowestApplicableChargebeeQuantity = (addonObj = {}) => {
  const {
    metaData: { min = 1, unitQuantity = 1 }
  } = addonObj
  return min / unitQuantity
}
const getLowestApplicableTierForAddon = (addonObj = {}) => {
  const { tiers = [] } = addonObj
  const minChargebeeUnitToStartWith = getLowestApplicableChargebeeQuantity(addonObj)
  let lowestApplicableTierPrice = 0
  tiers.forEach((tier) => {
    if (doesQuantityFallUnderTier(minChargebeeUnitToStartWith, tier)) {
      lowestApplicableTierPrice = tier.price
    }
  })
  return lowestApplicableTierPrice
}
const isExpectedStatus = ({ status = '' } = {}, expectedStatus) => {
  return status.toLowerCase() === expectedStatus
}
const getFormattedDate = (seconds = 0, toIntlString) => {
  let d = new Date(seconds * 1000)
  let year = d.getFullYear()
  let day = d.getDate()
  let month = toIntlString(MONTH_TO_DISPLAY_NAME[d.getMonth()])
  return `${day} ${month.substring(0, 3)} ${year}`
}
const getMissingFeaturesInCurPlan = (newSubscription, curSubscription, featureMatrix) => {
  const newSubsFeaturesMap = {}
  delve(featureMatrix, `${newSubscription.planId}.features`, []).forEach(({ featureId }) => {
    newSubsFeaturesMap[featureId] = true
  })
  const curSubsFeatures = delve(featureMatrix, `${curSubscription.planId}.features`, [])
  return curSubsFeatures.filter(({ featureId }) => !newSubsFeaturesMap[featureId])
}
const isNonRecurringAddon = ({ chargeType = '' } = {}) => {
  return chargeType === ADDON_NON_RECURRING
}
const hasSubCancelledBecauseOfCard = (subscription, card) => {
  return (
    isExpectedStatus(subscription, SUBSCRIPTION_STATUS.CANCELLED) &&
    isExpectedStatus(card, CARD_EXPIRED)
  )
}

const shouldProvideCardDetails = (card, isActiveSub, renewalEstimateTotal) =>
  isFaultyCard(card) && (!isActiveSub || renewalEstimateTotal === 0)

const isFaultyCard = (card) => {
  return !card || isExpectedStatus(card, CARD_EXPIRED)
}
const doesSubscriptionHasCurrencyChange = (newSubscription = {}, curSubscription = {}) => {
  return newSubscription.currencyCode !== curSubscription.currencyCode
}
const isPerUnitAddonBasedOnUsers = ({ unit, pricingModel } = {}) => {
  return (
    pricingModel === PLAN_AND_ADDON_PRICING_MODEL_TYPES.PER_UNIT &&
    unit === ADDON_PER_PRICING_UNIT.USER
  )
}
const getSelectedAddonsApplicableForThisPlan = (applicableAddons, curSelectedAddons = []) => {
  return curSelectedAddons.filter((addon) =>
    applicableAddons.some((applicableAddon) => applicableAddon.id === addon.id)
  )
}

const getUniqueAddonAfterPlanChange = (addons) => {
  const uniqueAddonObj = {}
  return addons.filter(({ id }) => {
    if (!uniqueAddonObj[id]) {
      uniqueAddonObj[id] = true
      return true
    }
    return false
  })
}

const getUserName = ({ firstName, lastName, email }) => {
  return firstName && lastName ? `${firstName} ${lastName}` : email
}
const getCheckoutPageURL = (id = ':id') => {
  return `${BILLING_BASE_PATH}/${id}/checkout`
}

const getDetailsPageURL = (id = ':id') => {
  return `${BILLING_BASE_PATH}/${id}/details`
}

const getDynamicInvoicesPageURL = (id = ':id') => {
  return `${BILLING_BASE_PATH}/${id}/invoices`
}

const getPaymentPageURL = (id = ':id') => {
  return `${getCheckoutPageURL(id)}/payment`
}
const getUpdatePaymentURL = (id = ':id') => {
  return `${BILLING_BASE_PATH}/${id}/updatepayment`
}
const getBillingFrequenciesList = (intl, currencyCode, featureMatrix) => {
  const curSelCurrency = currencyCode.toLowerCase()
  const applicableBillingPeriodUnits = featureMatrix.availablePlanFilters[curSelCurrency]
  const { month: applicableBillingPeriods } = applicableBillingPeriodUnits
  const menus = []
  applicableBillingPeriods.forEach((applicableBillingPeriod) => {
    const menu = {}
    const key = `${applicableBillingPeriod}_month`
    menu.value = key
    menu.dName = getIntlBillingCycleDescription(applicableBillingPeriod, intl)
    menus.push(menu)
  })
  return menus
}
const getCurrencyList = (featureMatrix, currentCurrency = '') => {
  return Object.keys(featureMatrix.availablePlanFilters)
    .filter((entry) => {
      if (featureMatrix.availablePlanFilters[entry].hasOwnProperty('active')) {
        const { active } = featureMatrix.availablePlanFilters[entry]
        if (!active && currentCurrency.toLowerCase() === entry) {
          return true
        }
        return active
      }
      return true
    })
    .map((entry) => entry.toUpperCase())
}

const gotoBills = (history) => gotoPage(history, BILLING_BASE_PATH)

const getPerUnitBillingAmount = (curSelPlanObj, curSubscription, unchangedSubscription) => {
  if (!curSelPlanObj) {
    return null
  }
  const { id: changedPlanId, period = 1 } = curSelPlanObj
  const { planQuantity, currencyCode: changedCurrencyCode } = curSubscription
  const { planId: unchangedPlanId, currencyCode: unchangedCurrencyCode } = unchangedSubscription
  let perPeriodUnitPlanPrice = getPerPeriodUnitPlanValue(curSelPlanObj)
  if (changedPlanId === unchangedPlanId && changedCurrencyCode === unchangedCurrencyCode) {
    perPeriodUnitPlanPrice = unchangedSubscription.planUnitPrice / period
  }
  return perPeriodUnitPlanPrice * planQuantity
}
const getPlanBillingDesc = (
  intl,
  price,
  planQuantity = 0,
  pricingModel,
  billingPeriodUnit,
  planUnitKey,
  planQuantityText
) => {
  let mainDesc, secondaryDesc
  const translationPeriodKey = `bills.billing_period.${billingPeriodUnit}`
  const translatedPeriodUnit = intl(translationPeriodKey)
  const planQuantitySingularUnit = `bills.addon_unit.singular.${planUnitKey.toLowerCase()}`
  const stairstepPlanText = isExpectedValue(
    pricingModel,
    PLAN_AND_ADDON_PRICING_MODEL_TYPES.STAIRSTEP
  )
    ? `${intl('bills.fm_pricing.up_to')}${' '}`
    : ''
  if (pricingModel === PLAN_AND_ADDON_PRICING_MODEL_TYPES.FLAT_FEE) {
    mainDesc = !!planQuantityText ? planQuantityText : intl('bills.flat_fee')
    secondaryDesc = price
  } else {
    mainDesc = `${stairstepPlanText}${planQuantity}${' '}${getPluralizedKey(
      planQuantity,
      intl(planQuantitySingularUnit),
      intl(`bills.addon_unit.plural.${planUnitKey.toLowerCase()}`)
    )}`
    secondaryDesc = price
  }
  if (translationPeriodKey !== translatedPeriodUnit) {
    secondaryDesc += `/${translatedPeriodUnit}`
  }
  return {
    mainDesc,
    secondaryDesc
  }
}

const addonBillingDescForHigherPeriod = ({
  intl,
  businessQty,
  unit,
  businessQtyMultiplier,
  convertedPriceValue,
  secondaryUnit,
  period,
  isOrchPack = false,
  conversionUnit = null
}) => {
  let periodUnitForPeriod = !!period && intl(`bills.period_unit.${period}`)
  if (!!periodUnitForPeriod && periodUnitForPeriod.startsWith('bills.period_unit.')) {
    // fallback when the period is other than (1, 3, 6, 12)
    periodUnitForPeriod = `${period} ${intl('bills.months').toLowerCase()}`
  }
  return {
    mainDesc: `${businessQty} ${unit} (${businessQty * businessQtyMultiplier} ${
      !!conversionUnit
        ? intl(
            `bills.addon_unit.${
              businessQty * businessQtyMultiplier > 1 ? 'plural' : 'singular'
            }.${conversionUnit}`
          )
        : intl(isOrchPack ? 'bills.orchestration.transactions' : 'bills.bot_session.sessions')
    })`,
    secondaryDesc: `${convertedPriceValue}/${secondaryUnit}${
      !!periodUnitForPeriod ? `/${periodUnitForPeriod}` : ''
    }`
  }
}

const getAddonBillingDesc = (addonObj, intl, subscription, invoiceData, planPeriod) => {
  //used in summary section only
  const { planQuantity } = subscription
  const {
    currencyCode,
    period,
    periodUnit,
    metaData: { unitQuantity = 0, conversionQuantity } = {},
    pricingModel,
    unit,
    id
  } = addonObj
  const quantityFromInvoiceData = getQuantityFromLineItem(id, invoiceData)
  const userSelectedBusinessQuantity = quantityFromInvoiceData * unitQuantity
  //line item addon amount will have unitAmount for all the period of the plan.
  const perUnitAddonAmount = getUnitAmountOfLineItem(id, invoiceData) / planPeriod
  let convertedPriceValue = getConvertedPriceValue(perUnitAddonAmount, currencyCode)
  const { businessQuantity, unitTranslation } = getAddonDetailedDescription(
    addonObj,
    intl,
    quantityFromInvoiceData
  )
  const { unitTranslation: secondaryUnitTranslation } = getAddonDetailedDescription(addonObj, intl)
  if (
    isNonRecurringAddon(addonObj) &&
    pricingModel === PLAN_AND_ADDON_PRICING_MODEL_TYPES.FLAT_FEE
  ) {
    return {
      mainDesc: intl('bills.flat_fee')
    }
  } else if (conversionQuantity) {
    const addonPrice = getUnitAmountOfLineItem(id, invoiceData)
    const isOrchPack = isOrchestrationAddon(addonObj)
    const conversionUnit = delve(addonObj, `metaData.conversionUnit`, null)
    //@todo Get the conversionQuantity name(transaction/sessions) from BE and pass it
    //in the below addonBillingDescForHigherPeriod function.
    //Also remove the isOrchPack check - BILLING-4843
    return addonBillingDescForHigherPeriod({
      intl,
      businessQty: userSelectedBusinessQuantity,
      unit: unitTranslation,
      businessQtyMultiplier: conversionQuantity,
      convertedPriceValue: getConvertedPriceValue(addonPrice, currencyCode),
      secondaryUnit: secondaryUnitTranslation,
      period,
      isOrchPack,
      conversionUnit
    })
  } else {
    const translationPeriodKey = `bills.billing_period.${periodUnit}`
    const translatedPeriodUnit = intl(translationPeriodKey)
    let mainDesc = `${
        !!unitTranslation ? `${userSelectedBusinessQuantity} ${unitTranslation}` : ''
      } `,
      secondaryDesc = `${
        isNonRecurringAddon(addonObj) && !!isPhoneCreditAddon ? '' : convertedPriceValue
      }${businessQuantity > 1 ? `/${businessQuantity} ` : ''}${
        secondaryUnitTranslation &&
        (businessQuantity > 1 ? secondaryUnitTranslation : `/${secondaryUnitTranslation}`)
      }`
    if (periodUnit && translationPeriodKey !== translatedPeriodUnit) {
      secondaryDesc += `/${translatedPeriodUnit}`
    }
    // eslint-disable-next-line default-case
    switch (pricingModel) {
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.PER_UNIT:
        // eslint-disable-next-line default-case
        switch (unit) {
          case ADDON_PER_PRICING_UNIT.USER:
            mainDesc = getPluralizedKey(
              planQuantity,
              intl(`bills.single_user`, { count: planQuantity }),
              intl(`bills.multiple_user`, { count: planQuantity })
            )
            break
        }
        break
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.TIERED:
        secondaryDesc = ''
        break
      case PLAN_AND_ADDON_PRICING_MODEL_TYPES.FLAT_FEE:
        mainDesc = ''
        break
    }
    return {
      mainDesc,
      secondaryDesc
    }
  }
}
const getAddonDetailedDescription = (
  { pricingModel = '', unit = '', metaData: { unitQuantity = 0, addonUnitKey = '' } = {} } = {},
  toIntlString,
  invoiceQuantity = 0
) => {
  let businessQuantity = '',
    unitTranslation = ''
  //the Flat_Fee model is not handled here cause the values are empty for them which we
  //already set through default values for the variables
  // eslint-disable-next-line default-case
  switch (pricingModel.toLowerCase()) {
    case PLAN_AND_ADDON_PRICING_MODEL_TYPES.STAIRSTEP:
    case PLAN_AND_ADDON_PRICING_MODEL_TYPES.PER_UNIT:
    case PLAN_AND_ADDON_PRICING_MODEL_TYPES.VOLUME:
    case PLAN_AND_ADDON_PRICING_MODEL_TYPES.TIERED:
      const unitLower = !!addonUnitKey ? addonUnitKey.toLowerCase() : unit.toLowerCase()
      businessQuantity = `${parseInt(unitQuantity) || 0}`
      unitTranslation = !!unitLower
        ? `${toIntlString(
            `bills.addon_unit.${
              (invoiceQuantity ? invoiceQuantity : businessQuantity) > 1 ? 'plural' : 'singular'
            }.${unitLower}`
          )}`
        : ''
      break
  }
  return {
    businessQuantity,
    unitTranslation
  }
}

const getAddonTranslatedDesc = (intl, i18nType, messages, descriptionLink) => {
  let key = `bills.addons.${i18nType}.description`
  const addonDescription = intl(messages[key] ? key : `bills.addons.empty.description`)
  return (
    <>
      {addonDescription}
      {!!descriptionLink && (
        <Anchor fontSize="small" href={descriptionLink} target="_blank">
          &nbsp;{intl('bills.more_details')}
        </Anchor>
      )}
    </>
  )
}

const checkoutSubscription = (queryParam = '', history, id, isReadOnlyMode) => {
  !isReadOnlyMode && gotoPage(history, getCheckoutPageURL(id), queryParam)
}
const editSubscription = (
  { showComparePlanPage = false, history, id, isReadOnlyMode, manageTrial = false },
  event = null
) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  let searchParams = new URLSearchParams()
  showComparePlanPage && searchParams.set('cp', true)
  manageTrial && searchParams.set('manageTrial', true)
  let params = searchParams.toString()
  checkoutSubscription(params ? '?' + params : '', history, id, isReadOnlyMode)
}
const isExpectedValue = (value, expectedValue) => {
  return value === expectedValue
}
const getCategoryInfoForPlan = (planId, featureMatrix = { category: {} }) => {
  let categoryId = delve(featureMatrix, `${planId}.category`)
  return categoryId && featureMatrix.category[categoryId] ? categoryId : null
}

/**
 * @Author Sumeet Sood
 * @param {object} curSelPlanObj Currently selected plan object fetched from /plans api
 * @param {object} curSubscription currentSubscription object which includes unsaved changes made by user
 * @returns {string} comma separated addonIds which be sent as queryParam in /addon api. api will return
 *   addon object for these ID's.
 */
const getAddonsIdsForApiCall = ({ curSelectedPlanObject = {}, curSubscription = {} }) => {
  const subscriptionAddons = curSubscription.addons || []
  const applicableAddons = curSelectedPlanObject.applicableAddons || []
  const missingAddons = []

  for (let i = 0; i < applicableAddons.length; i++) {
    const curAddonID = applicableAddons[i].id
    missingAddons.push(curAddonID)
  }

  for (let i = 0; i < subscriptionAddons.length; i++) {
    const curAddonID = subscriptionAddons[i].id
    if (!missingAddons.includes(curAddonID)) {
      missingAddons.push(curAddonID)
    }
  }

  return missingAddons
}

const getOfferApplicableForPlan = (planId, featureMatrix) => {
  if (!featureMatrix[planId]) return false
  const { applicableOffers = [] } = featureMatrix[planId]
  return featureMatrix[planId] && applicableOffers.length > 0 && applicableOffers[0]
}

const getAddonsByIdRequestData = (
  addonIds = [],
  subscriptionId,
  planId,
  billingGatewayConfigId,
  currencyCode
) => ({
  addon_ids: addonIds.join(','),
  subscription_id: subscriptionId,
  plan_id: planId,
  billing_gateway_config_id: billingGatewayConfigId,
  currency_code: currencyCode
})

const getPlanQueryParams = ({
  planId,
  billingPeriod,
  currencyCode,
  brandId,
  billingPeriodUnit,
  subscriptionId
}) => ({
  params: {
    ...(planId && { 'id[is]': planId }),
    ...(billingPeriod && { 'period[is]': billingPeriod }),
    ...(currencyCode && { 'currency_code[is]': currencyCode.toUpperCase() }),
    ...(brandId && { 'brand_id[is]': brandId }),
    ...(billingPeriodUnit && { 'period_unit[is]': billingPeriodUnit }),
    ...(subscriptionId && { subscription_id: subscriptionId })
  }
})

const getCouponsQueryParams = (ids) => ({
  params: {
    'id[in]': `[${ids}]`
  }
})

const getAutoActivateSubscriptionQueryParam = (action) => ({
  params: { action }
})

const sendBillingGatewayConfigIdInQueryParam = (billingGatewayConfigId, otherPrams = {}) => ({
  params: { ...otherPrams, billing_gateway_config_id: billingGatewayConfigId }
})

const isVendorPcVersionV2 = (curSubscription) => curSubscription.vendorPcVersion === '2.0'

const getFeatureMappingQueryParams = (brandId, billingGatewayConfigId) => ({
  params: {
    brand_id: brandId,
    billing_gateway_config_id: billingGatewayConfigId
  }
})

const getDowngradeDescriptionForPlanNames = (
  featureMatrix,
  fromPlanId,
  fromPlanDisplayName,
  toPlanId,
  toPlanDisplayName,
  toIntlString
) => {
  if (featureMatrix && fromPlanId && toPlanId) {
    fromPlanDisplayName = `${fromPlanDisplayName} ${toIntlString('bills.plan')}`
    toPlanDisplayName = `${toPlanDisplayName} ${toIntlString('bills.plan')}`
    const fromCategory = getCategoryInfoForPlan(fromPlanId, featureMatrix)
    const fromCategoryName =
      fromCategory && delve(featureMatrix, `category.${fromCategory}.displayName`, null)
    const toCategory = getCategoryInfoForPlan(toPlanId, featureMatrix)
    const toCategoryName =
      toCategory && delve(featureMatrix, `category.${toCategory}.displayName`, null)
    return fromCategoryName && toCategoryName && fromCategoryName !== toCategoryName
      ? [`${fromCategoryName} - ${fromPlanDisplayName}`, `${toCategoryName} - ${toPlanDisplayName}`]
      : [fromPlanDisplayName, toPlanDisplayName]
  }
  return []
}

const renderReadOnlyMode = (billingContext, customer = {}, subscription = {}) => {
  const canShowCheckout = canShowSubscriptionCheckout(subscription, customer, billingContext)
  const isPending = isExpectedStatus(subscription, SUBSCRIPTION_STATUS.PENDING_UPDATE)
  return !canShowCheckout || isPending
}

const getInvoiceDataFromEstimate = ({ invoiceEstimate, nextInvoiceEstimate } = {}) => {
  return invoiceEstimate || nextInvoiceEstimate || {}
}

const getNonRecurringAndSpecialAddonsArray = (applicableAddons = [], addonsObjects = {}) => {
  return applicableAddons.filter(({ id: addonId = '' }) => {
    const addon = addonsObjects[addonId]
    if (!addon) return false
    return isNonRecurringAddon(addon) || isBotSessionAddon(addon)
  })
}

const getInfoFromEstimate = (estimateData = {}, key, defaultValue) => {
  const invoice = getInvoiceDataFromEstimate(estimateData)
  //total will also have the taxes and discount value in it so using subtotal
  return delve(invoice, key, defaultValue)
}
const getTotalOfGivenParamForEstimate = (param, estimateData = {}) => {
  let items = getInfoFromEstimate(estimateData, param, [])
  let totalOfItems = Array.isArray(items) && items.reduce((acc, { amount = 0 }) => acc + amount, 0)
  return totalOfItems || 0
}

const getFilteredLineItemObject = (id, lineItems = []) =>
  lineItems.filter(({ entityId }) => entityId === id)

const getBillingAmountOfLineItem = (id, currencyCode, { lineItems }) => {
  let sumOfSplitPhoneCredit = 0
  if (isPhoneCreditAddon({ id })) {
    lineItems.forEach((item) => {
      if (item.entityId.includes('phonecredits')) {
        sumOfSplitPhoneCredit = item.amount + sumOfSplitPhoneCredit
      }
    })
    return getConvertedPriceValue(sumOfSplitPhoneCredit, currencyCode)
  }
  return getConvertedPriceValue(
    delve(getFilteredLineItemObject(id, lineItems), '0.amount', 0),
    currencyCode
  )
}

const getUnitAmountOfLineItem = (id, { lineItems }) => {
  if (isPhoneCreditAddon({ id })) {
    return lineItems[0].unitAmount
  }
  return delve(getFilteredLineItemObject(id, lineItems), '0.unitAmount', 0)
}
const getQuantityFromLineItem = (id, { lineItems }) =>
  delve(getFilteredLineItemObject(id, lineItems), '0.quantity', 0)

const isContractApplicableForBrand = (subscription, brandInfo) =>
  delve(brandInfo, `contractApplicable`, false) && delve(subscription, `contractTerm`, false)

const getIntlBillingCycleDescription = (planBillingPeriod, intl, hasPaidPrefix = true) => {
  const translatedBillingCycle = intl(
    `${BILLING_CYCLE_DESCRIPTION_FOR_CONTRACTS_INTL_KEY[planBillingPeriod]}.period`
  )
  return hasPaidPrefix
    ? intl('bills.billing_freq_description', {
        billingFrequency: translatedBillingCycle
      })
    : translatedBillingCycle
}

const getBillingCycle = (brandInfo, subscription) => {
  const contractTenureForBrand = delve(brandInfo, `contractTenureMonths`, 1)
  return Math.floor(contractTenureForBrand / subscription.billingPeriod)
}

const getReactivationTrialPeriodDate = () => {
  const now = new Date()
  now.setTime(now.getTime() + REACTIVATION_TRIAL_PERIOD)
  return Math.round(now.getTime() / 1000)
}

const doesSubscriptionHasAnyBillingAmount = (
  { addons = [], planUnitPrice, showAddon, planQuantity } = {},
  featureMatrix = {},
  curSelPlanObj = {}
) => {
  let hasOffer = false
  if (featureMatrix && featureMatrix[curSelPlanObj.id]) {
    const { applicableOffers = [] } = featureMatrix[curSelPlanObj.id]
    hasOffer = applicableOffers.length > 0 && applicableOffers[0]
  }
  // As part of growth freemium campaign we are offering
  // 3 free user licenses. hence there will no billing ammout
  // for this case
  if (!!hasOffer && planQuantity <= 3 && !addons.length) return false

  //if addons are present it ideally means they will have an amount.
  //Mandatory addons(Flat_fee addons - eg:PlatformFee in FreshTeam)
  //will have a price 0 in free plans
  //showAddon Flag indicates if a product as addons or not excluding the
  //mandatory addons.
  return planUnitPrice || (addons.length && showAddon)
}

const isOfflineCustomer = ({ autoCollection = CUSTOMER_AUTO_COLLECTION_OFF }) =>
  autoCollection === CUSTOMER_AUTO_COLLECTION_OFF

const isResellerSubscription = ({
  cfReseller = CONST.YES_OR_NO_FLAG.NO,
  cfResellerPaying = CONST.YES_OR_NO_FLAG.NO
}) =>
  cfReseller.toLowerCase() === CONST.YES_OR_NO_FLAG.YES ||
  cfResellerPaying.toLowerCase() === CONST.YES_OR_NO_FLAG.YES

const isSelfServeFeatureEnabled = (customer, subscription) => {
  const isOfflineUser = isOfflineCustomer(customer)
  return (
    !isOfflineUser ||
    delve(subscription, 'offlineSelfServe', SELF_SERVE_OFFLINE.OFF) === SELF_SERVE_OFFLINE.ON
  )
}

const canShowSubscriptionCheckout = (subscription, customer, billingState) => {
  const { pendingDueSubscriptions } = useSelector((state) => {
    return state.invoices
  })
  const { id } = subscription
  const isOfflineUser = isOfflineCustomer(customer)
  const isResellerUser = isResellerSubscription(subscription)
  const isSelfServeFeatureFlagEnabled = isSelfServeFeatureEnabled(customer, subscription)
  return (
    isSelfServeFeatureFlagEnabled &&
    !isResellerUser &&
    (!isOfflineUser ||
      (isOfflineUser &&
        isExpectedStatus(subscription, SUBSCRIPTION_STATUS.ACTIVE) &&
        !pendingDueSubscriptions[id]))
  )
}

const toggleFreshChatWidgetVisibility = (show) => {
  try {
    if (window.fcWidget) {
      window.fcWidget[show ? 'show' : 'hide']()
    }
  } catch (e) {
    window.Sentry && window.Sentry.captureException(new Error(e))
  }
}

const isAddonDowngraded = (
  subscription,
  { id: changedAddonID, quantity: changedAddonQuantity }
) => {
  const curAddonQuantityInDBForSubscription = getAddonQuantityFromSubscription(
    subscription,
    changedAddonID
  )
  return (
    curAddonQuantityInDBForSubscription &&
    changedAddonQuantity < curAddonQuantityInDBForSubscription
  )
}

const getAddonQuantityFromSubscription = ({ addons = [] }, addonID) => {
  const filteredAddonArray = addons.filter((addon) => addon.id === addonID)
  return delve(filteredAddonArray, '0.quantity', 0)
}
/**
 *
 * @param {*} curPlanObj -Current Active PlanObject
 * @param {*} newPlanObj - Currently Iterated Plan Object
 * @param {*} featureMatrix - Store Slice
 * @returns {boolean} whether user is allowed to select currently iterated plan
 */
const shouldAllowSwitchToGivenPlanForOfflineCustomer = (curPlanObj, newPlanObj, featureMatrix) => {
  const { id: curPlanId } = curPlanObj
  const { id: newPlanId } = newPlanObj
  if (curPlanId === newPlanId) {
    return true
  }
  const curPlanCategory = getCategoryObjForPlan(curPlanId, featureMatrix)
  const newPlanCategory = getCategoryObjForPlan(newPlanId, featureMatrix)
  const doCategoryCheck = !isUndefined(curPlanCategory) && !isUndefined(newPlanCategory)
  const isDiffCategory = doCategoryCheck && curPlanCategory.id !== newPlanCategory.id
  const curPlanCategoryWeight = delve(curPlanCategory, 'weight')
  const newPlanCategoryWeight = delve(newPlanCategory, 'weight')
  const curPlanUnitPeriodPrice = getPerPeriodUnitPlanValue(curPlanObj)
  const newPlanUnitPeriodPrice = getPerPeriodUnitPlanValue(newPlanObj)
  const isAllowedCategoryChange =
    !doCategoryCheck ||
    !isDiffCategory || //plan change between same category
    newPlanCategoryWeight > curPlanCategoryWeight //plan change between diff category, only movement to higher priority category is allowed.

  //currency change should not be there for offline customer so not including it here.
  const isPlanChangeAllowed = newPlanUnitPeriodPrice >= curPlanUnitPeriodPrice
  return isAllowedCategoryChange && isPlanChangeAllowed
}

const getCategoryObjForPlan = (planId, featureMatrix = { category: {} }) => {
  let categoryId = delve(featureMatrix, `${planId}.category`)
  const categoryObj = featureMatrix.category[categoryId]
  return categoryObj ? categoryObj : null
}

const isSubscriptionHavingSinglePlan = (applicablePlans, featureMatrix) => {
  const list = Object.keys(applicablePlans).filter((planId) => !!featureMatrix[planId])
  return Object.values(list).length === 1
}
const getInvoicesParams = ({ subscriptionId, status, offset }) => ({
  params: {
    ...(subscriptionId && { 'subscription_id[is]': subscriptionId }),
    ...(status && { 'status[in]': `[${status}]` }),
    ...(offset && { offset }),
    limit: 100,
    'sort_by[desc]': 'date'
  }
})

const getPostedAndPaymentDueInvoicesCount = (invoices) => {
  return invoices.reduce((curCount, { invoice }) => {
    const status = invoice.invoiceStatus
    if (status === INVOICE_STATUS.PAYMENT_DUE || status === INVOICE_STATUS.POSTED) {
      return curCount + 1
    }
    return curCount
  }, 0)
}

const isAlreadyOnHighestPlan = ({ planId, allSortedPlans = [], featureMatrix }) => {
  const highWeightageInCategory = Object.keys(featureMatrix.category).reduce(
    (largestCategory, currentCategoryKey) =>
      Math.max(largestCategory, featureMatrix.category[currentCategoryKey].weight),
    Number.NEGATIVE_INFINITY
  )
  const highestCategoryPlans = getHighestPlans(
    highWeightageInCategory,
    allSortedPlans,
    featureMatrix
  )
  const { length } = highestCategoryPlans
  return highestCategoryPlans[length - 1] === planId
}

const getCategoryIds = (currentApplicablePlans, featureMatrix) => {
  const getSortedCategoryIdByPriority = (cloudIds = [], featureMatrix) => {
    return cloudIds.sort(
      (id1, id2) =>
        featureMatrix.category[id1].displayPriority - featureMatrix.category[id2].displayPriority
    )
  }
  const getUniqueCategoryIdsFromPlanObject = (plans, featureMatrix = { category: {} }) => {
    const planIds = Object.keys(plans)
    const hierarchy = new Set()
    for (let planId of planIds) {
      const { category = '' } = delve(featureMatrix, planId, {})
      if (category && featureMatrix.category[category]) {
        hierarchy.add(category)
      }
    }
    for (let category of Object.keys(featureMatrix.category)) {
      if (featureMatrix.category[category] && featureMatrix.category[category].captureIntent) {
        hierarchy.add(category)
      }
    }
    return Array.from(hierarchy)
  }
  const categoryIds = getSortedCategoryIdByPriority(
    getUniqueCategoryIdsFromPlanObject(currentApplicablePlans, featureMatrix),
    featureMatrix
  )
  return categoryIds
}

const getHighestPlans = (highWeightageInCategory, allSortedPlans = [], featureMatrix) => {
  const highestCategoryIds = Object.keys(featureMatrix.category).filter(
    (categoryKey) => featureMatrix.category[categoryKey].weight === highWeightageInCategory
  )
  return allSortedPlans.filter((planId) => {
    const { category = '' } = delve(featureMatrix, planId, {})
    return highestCategoryIds.includes(category)
  })
}

const isEqualAddons = (originalAddons = [], currentAddons = []) => {
  if (originalAddons.length === currentAddons.length) {
    const addonsCompareObj = currentAddons.reduce(
      (prev, { quantity, id }) => ((prev[id] = quantity), prev),
      {}
    )
    return originalAddons.every((originalAddon) => {
      const { id, quantity } = originalAddon
      if (addonsCompareObj[id]) {
        return quantity === addonsCompareObj[id]
      }
      return false
    })
  }
  return false
}

const formatLeftAndRightUnit = ({ LHSQty, LHSUnit, RHSQty, RHSUnit }) => {
  return `${LHSQty} ${LHSUnit} = ${RHSQty} ${RHSUnit}`
}

const getGroupedAddonsFromSelected = (selectedAddons = [], addonObjects = {}) => {
  const groupAddons = []
  if (!selectedAddons.length) return groupAddons
  selectedAddons.forEach((addon) => {
    const { id } = addon
    const addonDetails = addonObjects[id]
    if (addonDetails) {
      const {
        metaData: { groupId = '' }
      } = addonDetails
      if (!!groupId) groupAddons.push({ addon, groupId })
    }
  })
  return groupAddons
}

const getSameGroupAddonAfterPlanChange = (
  currentApplicableAddons,
  previousGroupedAddons = [],
  allAddonsObject
) => {
  let addonWithSimilarGroup = []
  const previousGroupedAddonsMap = previousGroupedAddons.reduce((acc, { addon, groupId }) => {
    acc[groupId] = addon
    return acc
  }, {})
  currentApplicableAddons.forEach(({ id }) => {
    const { metaData: { groupId = '' } = {} } = allAddonsObject[id] || {}
    if (!!groupId && previousGroupedAddonsMap[groupId]) {
      const { quantity } = previousGroupedAddonsMap[groupId]
      addonWithSimilarGroup.push({
        id,
        quantity,
        object: 'addon'
      })
    }
  })
  return addonWithSimilarGroup
}

const sortInvoiceByPriority = (invoice1, invoice2) => {
  const aStatusPriority = invoicesStatusAndPriority[invoice1.invoice.invoiceStatus].priority
  const bStatusPriority = invoicesStatusAndPriority[invoice2.invoice.invoiceStatus].priority
  if (aStatusPriority < bStatusPriority) {
    return -1
  } else if (aStatusPriority > bStatusPriority) {
    return 1
  } else {
    return 0
  }
}

const canManageTrial = (subscription) => {
  const { planMetaData = {} } = subscription
  return isExpectedStatus(subscription, SUBSCRIPTION_STATUS.TRIAL) && planMetaData.hasTrialledAddon
}

const getGroupedManagedAssetsAddons = (plansAddons, addonObjects) => {
  let assertGroupAddons = []
  if (plansAddons) {
    for (let i = 0, len = plansAddons.length; i < len; i++) {
      const curAddon = addonObjects[plansAddons[i].id]
      const currAddonGrpId = !!curAddon && delve(curAddon, 'metaData.dropdownGroup', null)
      if (currAddonGrpId === GROUPED_ADDON.ASSERT_PACK) {
        assertGroupAddons.push(curAddon)
      }
    }
    assertGroupAddons.sort(function (addon1, addon2) {
      return delve(addon1, 'metaData.sortIndex', 1000) - delve(addon2, 'metaData.sortIndex', 1000)
    })
  }
  return assertGroupAddons
}

const isDropdownGroupAddon = (addonKey, addonObject) => {
  return !!addonKey && delve(addonObject, 'metaData.dropdownGroup', '') === addonKey
}

const userHasAccessToAtleastOneResellerSubscription = (subscriptionsData) =>
  subscriptionsData.some(({ subscription }) => isResellerSubscription(subscription))

const canShowSubscriptionIconInNavBar = (bundleTypes, userId, isAdmin) => {
  const currentLocalStorageValue = localStorage.getItem(CONST.SUBSCRIPTION_ICON_LOCAL_STORAGE_KEY)
  //To check if the same user as loggedIn in the same browser
  //If the current loggedIn userId hash does not match with the
  //existing hash in local storage we remove the local Storage
  //Becaz at this point we will not know if the newly loggedIn user
  //has access to subscriptions or not
  if (!!currentLocalStorageValue && !!userId) {
    if (currentLocalStorageValue === md5(String(userId))) {
      return true
    }
    localStorage.removeItem(CONST.SUBSCRIPTION_ICON_LOCAL_STORAGE_KEY)
  }
  return (
    !!isAdmin &&
    bundleTypes.some(({ name }) => name === CONST.BUNDLE_TYPE_NAMES.SALES360.toLowerCase())
  )
}

const isUserLicenseDependentAddon = ({ metaData: { max = -1 } }) => {
  const { field = '' } = max
  return field === PLAN_QUANTITY
}

const isPlanAddonQuantityDependentAddon = ({ metaData: { maxQuantityWithAddons = [] } }) =>
  maxQuantityWithAddons.length > 0

const getAddonToKeyMapping = (addonsList) =>
  addonsList.reduce(
    (allAddons, addon) => ({
      ...allAddons,
      ...{ [addon['id']]: addon }
    }),
    {}
  )

const getValidAddonPurchaseLimit = (licenseCount, curSubscription, curSelAddon) => {
  if (!isPlanAddonQuantityDependentAddon(curSelAddon)) {
    return Number(licenseCount)
  }
  const {
    metaData: { maxQuantityWithAddons = [] }
  } = curSelAddon
  let maxQuantity = Number(licenseCount)
  const { addons = [] } = curSubscription
  const addonsToKeyMapping = getAddonToKeyMapping(addons)
  maxQuantityWithAddons.forEach((addonId) => {
    const relatedAddon = delve(addonsToKeyMapping[addonId], 'quantity', 0)
    maxQuantity = maxQuantity + relatedAddon
  })
  return maxQuantity
}

const getMaxQuantityOfUserLicenseDependantAddons = (addons = [], addonObjects = {}) => {
  let max = -1
  let addonName = ''
  addons.forEach(({ id, quantity }) => {
    if (addonObjects[id] && isUserLicenseDependentAddon(addonObjects[id]) && max < quantity) {
      max = quantity
      addonName = delve(addonObjects, `${id}.metaData.dispName`, '')
    }
  })
  return { max, addonName }
}

const initializeChargebeeJs = async (
  curSubscription,
  setBillingState,
  chargebeeURL,
  onInitialization,
  onError
) => {
  const setCBJSLoaded = (value) => {
    setBillingState((oldState) => ({
      ...oldState,
      cbJSLoadedState: value
    }))
  }

  const loadChargebee = (curSubscription) => {
    return new Promise((resolve, reject) => {
      loadChargebeeJS(curSubscription)
        .then((chargebee) => {
          setCBJSLoaded(true)
          resolve(chargebee)
        })
        .catch((err) => {
          setCBJSLoaded(false)
        })
    })
  }

  if (typeof window !== 'undefined' && !!chargebeeURL) {
    let chargebee = await loadChargebee(curSubscription)
    const site = getSubdomain(chargebeeURL)
    if (chargebee) {
      if (isVendorPcVersionV2(curSubscription)) {
        await chargebee.init({
          site: site
        })
      }
      onInitialization()
    } else {
      onError(Error('unable-to-load-js'))
    }
  }
}

const loadChargebeeJS = (curSubscription) => {
  return new Promise((resolve, reject) => {
    const isVersionV2 = isVendorPcVersionV2(curSubscription)
    if (isVersionV2 && window && window.Chargebee) {
      resolve(window.Chargebee)
      return
    } else if (!isVersionV2 && window && window.ChargeBee) {
      resolve(window.ChargeBee)
      return
    }
    const script = document.createElement('script')
    script.src = isVersionV2 ? CONST.CHARGEBEE_JS_V2 : CONST.CHARGEBEE_JS_V1
    script.async = true
    script.onload = () => {
      if (window.Chargebee) {
        resolve(window.Chargebee)
      } else if (window.ChargeBee) {
        resolve(window.ChargeBee)
      } else {
        reject(new Error('Chargebee not found on the window object.'))
      }
    }
    script.onerror = (error) => {
      reject(error)
    }
    document.head.appendChild(script)
  })
}

const isProductIntentRaised = (error = '', errorsList = []) => {
  const {
    ERROR_STRINGS: { PRODUCT_INTENT_RAISED, CAPTURE_INTENT }
  } = CONST
  return errorsList.length > 0
    ? errorsList.some(({ action }) => action === CAPTURE_INTENT)
    : error === PRODUCT_INTENT_RAISED
}

const getSelectedDependentAddons = (dependentAddons = {}, addons = []) => {
  const { INBOUND, OUTBOUND, NUMBERS } = PHONE_CREDIT_SPLIT
  let selectedDependentAddons = []
  let phoneCreditAddon = {}
  let phonecreditAddonQuentity = 0
  let dependentAddonArr = addons.filter(({ id }) => {
    let newId = id.includes('phonecredits')
      ? id.replace(INBOUND, '').replace(OUTBOUND, '').replace(NUMBERS, '')
      : id
    return dependentAddons.includes(newId)
  })
  if (!!dependentAddonArr.length) {
    dependentAddonArr.map((addon) => {
      if (addon.id.includes('phonecredits')) {
        let newId = addon.id.replace(INBOUND, '').replace(OUTBOUND, '').replace(NUMBERS, '')
        phoneCreditAddon['id'] = newId
        phonecreditAddonQuentity = phonecreditAddonQuentity + addon.quantity
        phoneCreditAddon['quantity'] = phonecreditAddonQuentity
      } else {
        selectedDependentAddons.push(addon)
      }
    })
    if (!!Object.keys(phoneCreditAddon).length) selectedDependentAddons.push(phoneCreditAddon)
  }

  return selectedDependentAddons
}

const isFeatureGroupAvailableInTranslationFile = (groupName) =>
  !!delve(defaultTranslation, `bills.feature_categories.${groupName}`, null)

const sortTheAddonBasedOnPriority = (addonList, addonObjects) => {
  return addonList.sort((addon1, addon2) => {
    // This condition is to handle sort of dependent addons and
    // normal full box , half box addons
    const addon1Id = !!addon1.id ? addon1.id : addon1
    const addon2Id = !!addon2.id ? addon2.id : addon2
    return (
      delve(addonObjects, `${addon2Id}.metaData.sortPriority`, -1) -
      delve(addonObjects, `${addon1Id}.metaData.sortPriority`, -1)
    )
  })
}

const getValidAddonsPresentForThePlan = (
  plansAddons = [],
  addonObjects,
  manageTrial,
  curSubscription
) => {
  let validAddonsPresentForThePlan = []
  let halfBoxAddons = []
  let fullBoxAddons = []
  let addonsHavingDependentAddons = []
  plansAddons.forEach((planAddon) => {
    const curAddon = delve(addonObjects, planAddon.id, '')
    if (!!curAddon) {
      const isTrial = isExpectedStatus(curSubscription, SUBSCRIPTION_STATUS.TRIAL)
      const canShowAddon = manageTrial ? delve(curAddon, 'canBeTrialled', false) : true
      let isNonRecurring = isNonRecurringAddon(curAddon)
      const isDependentAddon = addonObjects.dependentAddons[curAddon.id]
      if (
        isNonRecurring &&
        (isOrchestrationAddon(curAddon) ||
          isDayPassAddon(curAddon) ||
          isBotSessionAddon(curAddon) ||
          isPhoneCreditAddon(curAddon))
      ) {
        isNonRecurring = !isTrial
      }
      const canShowAddonInUI =
        curAddon.online && canShowAddon && !isNonRecurring && !isDependentAddon
      if (canShowAddonInUI) {
        validAddonsPresentForThePlan.push(planAddon)
      }
    }
  })
  validAddonsPresentForThePlan.forEach((addon) => {
    const curAddon = delve(addonObjects, addon.id, '')
    //This is added for Backward Compability and should be removed once the BE config is added in prod
    const isFullBoxAddon = isPhoneCreditAddon(curAddon) || isBotSessionAddon(curAddon)
    const {
      metaData: { cardSize = ADDON_BOX_SIZES.HALF_CARD },
      dependentAddon = []
    } = curAddon
    if (dependentAddon.length > 0) {
      addonsHavingDependentAddons.push(curAddon)
    } else if (cardSize === ADDON_BOX_SIZES.FULL_CARD || isFullBoxAddon) {
      fullBoxAddons.push(curAddon)
    } else {
      halfBoxAddons.push(curAddon)
    }
  })
  return { addonsHavingDependentAddons, fullBoxAddons, halfBoxAddons }
}

const getPlansToBeShownInTheUI = (applicablePlans, featureMatrix) =>
  Object.keys(applicablePlans).filter(
    (applicablePlan) =>
      !delve(applicablePlans[applicablePlan], 'metaData.selfServeDisabled', false) &&
      !!featureMatrix[applicablePlan]
  ).length

const canShowCheckoutExistingHostedPage = (
  totalBillingAmount,
  card,
  curSubscription,
  offlineCustomer,
  renewalEstimateTotal
) => {
  const isTrial = isExpectedStatus(curSubscription, SUBSCRIPTION_STATUS.TRIAL)
  const isActiveSub = isExpectedStatus(curSubscription, SUBSCRIPTION_STATUS.ACTIVE)
  const isCancelledSub = isExpectedStatus(curSubscription, SUBSCRIPTION_STATUS.CANCELLED)
  if (!totalBillingAmount) {
    return false
  } else
    return (
      (shouldProvideCardDetails(card, isActiveSub, renewalEstimateTotal) ||
        isTrial ||
        isCancelledSub) &&
      !offlineCustomer
    )
}

export {
  CREDIT_TYPES,
  PLAN_AND_ADDON_PRICING_MODEL_TYPES,
  ADDON_PER_PRICING_UNIT,
  SUBSCRIPTION_STATUS,
  CARD_EXPIRED,
  CARD_EXPIRING,
  CARD_ACTIVE,
  SALES360_BUNDLE_NAME,
  BILLING_BASE_PATH,
  CUSTOMER_AUTO_COLLECTION_OFF,
  CUSTOMER_AUTO_COLLECTION_ON,
  ANNUAL_SUB,
  SUB_ACTIVATED_SUCCESSFULLY,
  SUBSCRIPTION_UPDATED_SUCCESSFULLY,
  TRIAL_CHANGE_SUCCESSFULLY,
  PAYMENT_METHOD_UPDATE_SUCCESS,
  PAYMENT_PAGE_URL_FETCH_FAILED,
  INTENT_CAPTURED_SUCCESSFULLY,
  SUCCESS,
  FAILURE,
  SUBSCRIPTION_STATUS_LIST,
  SUBSCRIPTION_POLLING_DURATION,
  SUBSCRIPTION_POLLING_INTERVAL,
  PHONE_CREDITS_DELAY_DURATION,
  BOT_SESSIONS_DELAY_DURATION,
  DAY_PASS_DELAY_DURATION,
  ORCH_PACK_POLL_DURATION_IN_MS,
  ORCH_PACK_DELAY_DURATION,
  ADDON_UNIT_TO_UI_COMPONENT_MAPPING,
  SELF_SERVE_OFFLINE,
  UI_COMP,
  DEFAULT,
  DEFAULT_CATEGORY_TYPE,
  CONTRACT_STATUS,
  BILLING_CYCLE_DESCRIPTION_FOR_CONTRACTS_INTL_KEY,
  INVOICE_STATUS,
  GROUPED_ADDON,
  MANAGED_ASSETS_GROUPED_ADDONS_ID,
  ADDON_BOX_SIZES,
  renderReadOnlyMode,
  getIntlBillingCycleDescription,
  getBillingAmountOfTieredPricingAddon,
  getLowestApplicableChargebeeQuantity,
  getLowestApplicableTierForAddon,
  doesQuantityFallUnderTier,
  getCategoryInfoForPlan,
  getConvertedPriceValue,
  getLowestCurrencyValue,
  isPhoneCreditAddon,
  isBotSessionAddon,
  isDayPassAddon,
  isConnectorAppsAddon,
  isConnectorAppsAutoRechargeAddon,
  isBotAutoRechargeAddon,
  isOrchestrationAddon,
  gotoPage,
  isExpectedValue,
  isPlanUpgradeRequest,
  getPlanMetaObj,
  parseNGetSelectedAddons,
  getPerPeriodUnitPlanValue,
  getAddonsBusinessUnit,
  getRemainingTrialDays,
  getPerPeriodUnitAddonAmount,
  getFormattedDate,
  getMissingFeaturesInCurPlan,
  hasSubCancelledBecauseOfCard,
  doesSubscriptionHasCurrencyChange,
  getSortedPlans,
  isPerUnitAddonBasedOnUsers,
  getSelectedAddonsApplicableForThisPlan,
  getUserName,
  getUpdatePaymentURL,
  isExpectedStatus,
  getBillingFrequenciesList,
  getCurrencyList,
  gotoBills,
  getPerUnitBillingAmount,
  getPlanBillingDesc,
  getAddonDetailedDescription,
  getAddonBillingDesc,
  isFaultyCard,
  editSubscription,
  getCurSubDomainInfo,
  getPlanQueryParams,
  getAutoActivateSubscriptionQueryParam,
  getFeatureMappingQueryParams,
  getAddonTranslatedDesc,
  getDowngradeDescriptionForPlanNames,
  isContractApplicableForBrand,
  getBillingCycle,
  getReactivationTrialPeriodDate,
  getInvoiceDataFromEstimate,
  getBillingAmountOfLineItem,
  getInfoFromEstimate,
  doesSubscriptionHasAnyBillingAmount,
  getQuantityFromLineItem,
  getCouponsQueryParams,
  getTotalOfGivenParamForEstimate,
  toggleFreshChatWidgetVisibility,
  canShowSubscriptionCheckout,
  isOfflineCustomer,
  isAddonDowngraded,
  getAddonQuantityFromSubscription,
  shouldAllowSwitchToGivenPlanForOfflineCustomer,
  isNonRecurringAddon,
  getInvoicesParams,
  invoicesStatusAndPriority,
  getPostedAndPaymentDueInvoicesCount,
  getUnitAmountOfLineItem,
  getCheckoutPageURL,
  getPaymentPageURL,
  getUniqueAddonAfterPlanChange,
  isAlreadyOnHighestPlan,
  getCategoryIds,
  getHighestPlans,
  isEqualAddons,
  formatLeftAndRightUnit,
  isSelfServeFeatureEnabled,
  getGroupedAddonsFromSelected,
  getSameGroupAddonAfterPlanChange,
  sendBillingGatewayConfigIdInQueryParam,
  isVendorPcVersionV2,
  sortInvoiceByPriority,
  canManageTrial,
  isResellerSubscription,
  getGroupedManagedAssetsAddons,
  isDropdownGroupAddon,
  userHasAccessToAtleastOneResellerSubscription,
  canShowSubscriptionIconInNavBar,
  DISCOUNT_ENTITY_TYPE,
  subscriptionNavList,
  getDetailsPageURL,
  getDynamicInvoicesPageURL,
  SUBSCRIPTION_ROUTES,
  PAGE_DISPLAY_KEY_MAPPING,
  getListOfBreadcrumbs,
  getNonRecurringAndSpecialAddonsArray,
  getOfferApplicableForPlan,
  isSubscriptionHavingSinglePlan,
  getMaxQuantityOfUserLicenseDependantAddons,
  isUserLicenseDependentAddon,
  getAddonsIdsForApiCall,
  AUTO_ACTIVATE_ACTION,
  initializeChargebeeJs,
  isProductIntentRaised,
  getAddonsByIdRequestData,
  isSubscriptionPlanPriceLessThanCatalog,
  getSelectedDependentAddons,
  isPlanAddonQuantityDependentAddon,
  getValidAddonPurchaseLimit,
  isFeatureGroupAvailableInTranslationFile,
  loadChargebeeJS,
  BILLING_CYCLE,
  sortTheAddonBasedOnPriority,
  getValidAddonsPresentForThePlan,
  getPerPeriodUnitAddonAmountForStairstepAddon,
  getPlansToBeShownInTheUI,
  getAddonToKeyMapping,
  shouldProvideCardDetails,
  canShowCheckoutExistingHostedPage
}
